<template>
  <VueContentHolder id="divMyDocumentsWrapper" :padding="[20, 25]">
    <VueListItem
      as="router-link"
      :to="item.to"
      :text="item.text"
      :contentAlignment="constants.flexAlignment.between"
      v-for="item in items"
      :key="item.id"
    ></VueListItem>
  </VueContentHolder>
</template>
<script>
import VueListItem from '@/components/shared/VueListItem/VueListItem.vue';
import VueContentHolder from '@/components/shared/VueContentHolder/VueContentHolder.vue';
import RoutesMyDocuments from '@/router/routes/secure/RoutesMyDocuments';
import RoutesSettings from '@/router/routes/secure/RoutesSettings';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';

export default {
  name: 'MyDocumentsNavigation',
  components: { VueListItem, VueContentHolder },
  data() {
    return {
      items: [
        {
          text: 'Sözleşme',
          to: `${RoutesSettings.MyDocuments.path}/${RoutesMyDocuments.Contracts.path}`,
        },
      ],
    };
  },
  computed: {
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
  },
};
</script>
